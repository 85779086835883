import React, { memo } from "react"
import Img from "gatsby-image"
import IconsAdded from "./IconsAdded"

const Whyus = ({ data }) => {
    return (
        <div className="aboutus-page-container position-relative">
            <div className="why-us">
                <div className="HighlightOne ">
                    <Img fluid={data.img2.childImageSharp.fluid} alt="icon" />
                    <p>
                        We believe that a Happy Customer isn't just a goal it's the outcome
                        of our dedication to treating each interaction as a chance to exceed
                        expectations.
                    </p>
                </div>
                <div className="HighlightTwo">
                    <Img fluid={data.scale.childImageSharp.fluid} />
                    <p>
                        Embracing the Customer as a Partner, we collaborate closely, valuing
                        their insights as catalysts for mutual growth.
                    </p>
                </div>
                <div className="HighlightThree">
                    <Img fluid={data.img1.childImageSharp.fluid} />
                    <p>
                        At our core, we ensure Everyone Should Be Heard, fostering an
                        environment where every voice matters, contributing to our
                        collective success.
                    </p>
                </div>
                <div className="HighlightFour">
                    <p style={{width:'100%'}}>Our core values stand as pillars guiding our every endeavor</p>
                </div>
                <div className="HighlightSix">
                    <Img fluid={data.light.childImageSharp.fluid} />
                    <p>
                        Continual Value Addition is our commitment, driving us to innovate,
                        refine, and deliver exceptional solutions that make a tangible
                        difference. These values are not just principles; they're the
                        essence of who we are and what we stand for.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default memo(Whyus)
