import React, { memo } from 'react'
import Companies from './Companies'
const About_clients = ({ data }) => {
    return (
        <div className="About-clients-within-home-page">
            <div className="happyClientAboutContainer">
                <div className="first">
                    <div>
                        <p className='about_companies_chosen'>We are chosen by companies to impower their digital journey</p>
                    </div>
                </div>
                <Companies data={data}/>
            </div>
        </div>
    )
}

export default memo(About_clients)
